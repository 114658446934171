.box {
    margin-top: 20px;
    display: flex;
    float: left;
    margin-left: 20px;
}

@media screen and (max-width: 600px) {
    .cardContent{
        display: none;
    }
}

.cardLinks {
    text-decoration: none;
}

.albumName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spacer {
    height: 40px;
    width: 100%;
}