.loginRoot{
    flex-grow: 1;
}

.headerTitle{
    flex-grow: 1;
}

.noDecoration {
    text-decoration: none;
    color: inherit;
}