main {
  padding-top: 20px;
  padding-bottom: 20px;
}

body {
  background-color: #191414;
}

h1{
  color: #fff;
}